import common_dk from './dk/common.json';
import common_en from './en/common.json';
import common_es from './es/common.json';
import common_hr from './hr/common.json';
import common_th from './th/common.json';
import common_ua from './ua/common.json';
import common_vn from './vi/common.json';

const appLanguages = {
  en: { ...common_en },
  da: { ...common_dk },
  vi: { ...common_vn },
  th: { ...common_th },
  hr: { ...common_hr },
  uk: { ...common_ua },
  es: { ...common_es },
};

export { appLanguages };
